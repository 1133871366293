import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('turbo:submit-end', this.reset.bind(this));
  }

  reset() {
    this.element.reset();
  }
}
