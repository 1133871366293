require('@rails/ujs').start();
require('@hotwired/turbo-rails');
require('@rails/activestorage').start();

/* eslint-disable import/no-unresolved, import/first */
import './controllers';
import './channels';

import Alpine from 'alpinejs';

window.Alpine = Alpine;
Alpine.start();
/* eslint-enabled import/no-unresolved import/first */
