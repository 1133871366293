/* eslint-disable no-debugger */
/* eslint-disable prefer-destructuring */
import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import { post } from '@rails/request.js';

class Upload {
  constructor(file) {
    this.directUpload = new DirectUpload(file, "/rails/active_storage/direct_uploads", this);
  }

  process() {
    this.insertUpload(this.directUpload);

    this.directUpload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const uploadData = { upload: { file: blob, filename: blob.filename }, signed_blob_id: blob.signed_id, direct_upload_id: this.directUpload.id };
        //debugger;
        this.postNewUpload(uploadData);
        // const response = post('/uploads', {
        //   body: uploadData,
        //   contentType: 'application/json',
        //   responseKind: 'json',
        // });
      }
    });
  }

  postNewUpload = async (params) => {
    // This sign is not saved in the database. Is used by Cloudinary temporally.
    const response = await post('/uploads', {
      body: params,
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    });
    if (response.ok) {
      // const html = await response.text();
      // Turbo.renderStreamMessage(html);
      const progress = document.querySelector(`#upload_direct_${this.directUpload.id} .progress`);
      // console.log("encontrada");
      // console.log(progress);
      const doneLabel = document.createElement("div");
      doneLabel.className = "pt-1 flex justify-center text-xs font-semibold text-gray-899 text-white";
      doneLabel.innerHTML = "Done &#x2713;";
      progress.appendChild(doneLabel);
      progress.style.transform = `translateX(-${100 - 100}%)`;
    } else {
      const progress = document.querySelector(`#upload_direct_${this.directUpload.id} .progress`);
      // console.log(response);
      // console.log(progress);
      const errorLabel = document.createElement("div");
      errorLabel.className = "pt-1 flex justify-center text-xs font-semibold text-red text-white";
      errorLabel.innerHTML = "Error &#x2715;";
      progress.appendChild(errorLabel);
      progress.style.transform = `translateX(-${100 - 100}%)`;
      progress.style.backgroundColor = "red";

      const upload_direct = document.querySelector(`#upload_direct_${this.directUpload.id}`);
      const divForTurboFrame = document.createElement("div");
      divForTurboFrame.className = "pt-1 flex justify-center text-xs font-semibold text-red";
      const turboFrameTag = document.createElement("turbo-frame");
      turboFrameTag.id = `error_drect_upload_${this.directUpload.id}`;
      divForTurboFrame.appendChild(turboFrameTag);
      upload_direct.appendChild(divForTurboFrame);

      const renderTurbo = async () => {
        const html = await response.text;
        Turbo.renderStreamMessage(html);
      };
      
      renderTurbo();
    }
  };

  insertUpload(directUpload) {
    const fileUpload = document.createElement("div");
    const id = directUpload.id;

    fileUpload.id = `upload_direct_${ id }`;
    fileUpload.className = "p-3 border-b";

    fileUpload.textContent = directUpload.file.name;

    const progressWrapper = document.createElement("div");
    progressWrapper.className = "relative h-6 overflow-hidden rounded-full border border-blue-400 bg-primary w-[100%]";
    fileUpload.appendChild(progressWrapper);

    const progressBar = document.createElement("div");
    progressBar.className = "progress h-full w-full flex-1 bg-reelSkyBlue";
    progressBar.style = "transform: translateX(-100%);";
    progressWrapper.appendChild(progressBar);

    const uploadList = document.querySelector("#uploads_uploaded");
    uploadList.appendChild(fileUpload);
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress", (event) => this.updateProgress(event));
  }

  updateProgress(event) {
    // console.log(event.loaded, event.total);
    const percentage = (event.loaded / event.total) * 100;
    const progress = document.querySelector(`#upload_direct_${this.directUpload.id} .progress`);
    // console.log("encontrada");
    // console.log(progress);
    // console.log(percentage);
    if(percentage<95)
      progress.style.transform = `translateX(-${100 - percentage}%)`;
  }
}

export default class extends Controller {
  static targets = ["fileInput"];

  static values = {
    mediaAllowed: String,
    maxSizeInMb: Number,
  }

  connect() {
    this.element.addEventListener("dragover", this.preventDragDefaults);
    this.element.addEventListener("dragenter", this.preventDragDefaults);
  }

  disconnect() {
    this.element.removeEventListener("dragover", this.preventDragDefaults);
    this.element.removeEventListener("dragenter", this.preventDragDefaults);
  }

  preventDragDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  trigger() {
    this.fileInputTarget.click();
  }

  acceptFiles(event) {
    event.preventDefault();
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    // console.log(files);
    [...files].forEach((f) => {
      //var reg = /video/;
      var reg = new RegExp(this.getMediaAllowed(this.mediaAllowedValue));
      if (reg.test(f.type)) {
        if (f.size < this.getMaxSizeAllowedInBytes(this.maxSizeInMbValue)) {
          new Upload(f).process();
        }
        else {
          this.insertDivFileNotValid(f, `Error: File size higher than ${this.maxSizeInMbValue} mb`);
        }
      } else {
          // not valid video format
          this.insertDivFileNotValid(f, "Error: Incompatible file format");
      }
    });
  }

  getMediaAllowed(railsConfigurationMediaAllowed) {
    // input "video/mp4,video/quicktime,video/webm,video/x-ms-wmv,
    // video/x-msvideo,video/x-matroska,video/x-flv,video/h264"
    const regExp = new RegExp('video/', 'g');

    // We have to return mp4|quicktime|webm|x-ms-wmv|x-msvideo|x-matroska|x-flv|h264
    return railsConfigurationMediaAllowed.replace(regExp, '').split(',').join('|');
  }

  getMaxSizeAllowedInBytes(sizeInMb) {
    // console.log(parseInt(sizeInMb, 10) * 1048576);
    return parseInt(sizeInMb, 10) * 1048576;
  }

  insertDivFileNotValid(file, message) {
    const fileUploadNotValid = document.createElement("div");
    const messageNotValid = document.createElement("div");

    // fileUploadNotValid.id = `upload_direct_${this.directUpload.id}`;
    fileUploadNotValid.className = "p-3 border-b";
    messageNotValid.className = "p-3 text-red-500";

    fileUploadNotValid.textContent = file.name;
    messageNotValid.textContent = message;

    fileUploadNotValid.appendChild(messageNotValid);

    const uploadList = document.querySelector("#uploads_uploaded");
    uploadList.appendChild(fileUploadNotValid);
  }

  // uploadFile(file) {
  //   const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
  //   upload.create((error, blob) => {
  //     if (error) {
  //       // Handle the error
  //     } else {
  //       const uploadData = { upload: { file: blob, filename: blob.filename }, signed_blob_id: blob.signed_id };
  //       debugger;
  //       post('/uploads', {
  //         body: uploadData,
  //         contentType: 'application/json',
  //         responseKind: 'json',
  //       });
  //     }
  //   });
  // }
}
