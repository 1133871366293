import CheckboxSeletAll from 'stimulus-checkbox-select-all';
import { destroy } from '@rails/request.js';

export default class extends CheckboxSeletAll {
  async destroy(event) {
    event.preventDefault();

    if (this.checked.length > 0) {
      const data = new FormData();
      this.checked.forEach((checkbox) => data.append('slugs[]', checkbox.value));

      const response = await destroy('/playlists/bulk', {
        body: data,
        responseKind: 'turbo-stream',
      });

      if (response.ok) this.checkboxAllTarget.checked = false;
    }
  }
}
