import { Controller } from 'stimulus';
import Sortable from 'sortablejs';
import { put } from '@rails/request.js';

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.updatePosition.bind(this),
    });
  }

  /* eslint-disable class-methods-use-this */
  async updatePosition(event) {
    await put(`/playlist_uploads/${event.item.dataset.id}`, {
      body: JSON.stringify({
        position: event.newIndex + 1,
      }),
    });
  }
  /* eslint-enable class-methods-use-this */
}
