import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'plan', 'selectPlan'];

  connect() {
    const selected = this.element.querySelector('#select-plan #plan').value;
    this.element.querySelectorAll('.plan_id')
      // eslint-disable-next-line no-param-reassign
      .forEach((i) => { i.value = JSON.parse(selected).stripe_price_id; });
  }

  changePlan(event) {
    const { target } = event;
    this.element.querySelectorAll('.plan_id')
      // eslint-disable-next-line no-param-reassign
      .forEach((i) => { i.value = JSON.parse(target.dataset.value).stripe_price_id; });
    this.element.querySelectorAll('.plan-price')
      // eslint-disable-next-line no-param-reassign
      .forEach((i) => { i.innerHTML = JSON.parse(target.dataset.value).price; });
    this.element.querySelectorAll('.plan-name')
      // eslint-disable-next-line no-param-reassign
      .forEach((i) => { i.innerHTML = JSON.parse(target.dataset.value).name; });
    this.element.querySelectorAll('.plan-storage')
      // eslint-disable-next-line no-param-reassign
      .forEach((i) => { i.innerHTML = JSON.parse(target.dataset.value).storage_minutes; });

    if (JSON.parse(target.dataset.value).free_trial) {
      this.element.querySelectorAll('.free-trial')
        .forEach((i) => { i.classList.remove('hidden'); });
    } else {
      this.element.querySelectorAll('.free-trial')
        .forEach((i) => { i.classList.add('hidden'); });
    }
  }
}
