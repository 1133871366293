import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['copy', 'button', 'success'];

  static values = {
    content: String,
  }

  copy() {
    if (this.contentValue) {
      navigator.clipboard.writeText(this.contentValue);
    } else {
      navigator.clipboard.writeText(window.location.href);
    }

    if (this.hasSuccessTarget) {
      this.buttonTarget.classList.toggle('hidden');
      this.successTarget.classList.toggle('hidden');

      setTimeout(() => {
        this.buttonTarget.classList.toggle('hidden');
        this.successTarget.classList.toggle('hidden');
      }, 1000);
    }
  }
}
