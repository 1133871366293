import Dropdown from 'stimulus-dropdown';

export default class extends Dropdown {
  connect() {
    super.connect();
  }

  toggle() {
    super.toggle();
  }

  hide(event) {
    super.hide(event);
  }

  hoverShow() {
    if (!this.showing) {
      this.toggle();
    }

    this.showing = true;
  }

  hoverHide(event) {
    if (!this.element.contains(event.relatedTarget)) {
      this.toggle();
      this.showing = false;
    }
  }
}
