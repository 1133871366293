import { Controller } from 'stimulus';
// import cloudinary from 'cloudinary-video-player';

export default class extends Controller {
  static targets = ['source', 'player'];

  static values = {
    cloudName: String,
  }

  static siteUrl = 'https://www.reeltoday.com';

  static logoImageUrl = 'https://res.cloudinary.com/reeltoday/images/q_auto/v1627443836/reeltoday.com/RT_Logo_R_Symbol_2C/RT_Logo_R_Symbol_2C.png'; // eslint-disable-line max-len

  connect() {
    const video = document.getElementById('reeltoday');
    video.src = this.sourceTargets[0].dataset.url;
    video.autoplay = true;
    this.player = video;

    // Add event listener for when the video ends
    video.addEventListener('ended', this.playNextVideo.bind(this));
  }

  changeSource(event) {
    this.switchActiveSource(event.currentTarget);
    const index = parseInt(event.currentTarget.dataset.videoPlayerSourceIndex, 10);
    // this.player.playlist().playAtIndex(index);

    const video = document.getElementById('reeltoday');
    video.src = this.sourceTargets[index].dataset.url;
    video.autoplay = true;
    this.player = video;
  }

  /* eslint-disable class-methods-use-this */
  switchActiveSource(newSourceElement) {
    document.getElementsByClassName('text-blue-500')[0].classList.remove('text-blue-500');
    newSourceElement.classList.add('text-blue-500');
  }

  getSources() {
    return this.sourceTargets.map((sourceElement) => ({
      publicId: sourceElement.id.replace('playlist_upload_', ''),
      info: {
        title: '',
      },
      poster: sourceElement.dataset.poster,
    }));
  }

  buildPlayer(cloudinaryInstance, sources) {
    const player = cloudinaryInstance.videoPlayer('reeltoday', {
      fluid: true,
      controls: true,
      muted: false,
      logoOnclickUrl: this.constructor.siteUrl,
      logoImageUrl: this.constructor.logoImageUrl,
      colors: {
        base: '#252525',
        accent: '#1b71bc',
        text: '#fff',
      },
    });

    player.sourceTypes(['mp4']);

    player.playlist(sources, {
      autoAdvance: true,
      repeat: false,
      presentUpcoming: false,
    });

    return player;
  }

  removePlayer() {
    this.playerTarget.remove();
  }

  playNextVideo() {
    // Find the current video index
    const currentVideoSrc = this.player.src;
    const currentIndex = this.sourceTargets.findIndex((source) => source.dataset.url === currentVideoSrc);

    // If there is a next video, play it
    if (currentIndex >= 0 && currentIndex < this.sourceTargets.length - 1) {
      const nextIndex = currentIndex + 1;
      const nextSource = this.sourceTargets[nextIndex];

      this.switchActiveSource(nextSource);

      this.player.src = nextSource.dataset.url;
      this.player.play();
    }
  }
}
