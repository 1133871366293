import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

window.Turbo = Turbo;

export default class extends Controller {
  static targets = ['button']

  static values = {
    messageContainerId: String,
    closeElementId: String,
  }

  connect() {
    this.buttonTarget.addEventListener('click', () => this.showMessage(), false);
    this.buttonTarget.addEventListener('mouseover', () => this.showMessage(), false);
    this.buttonTarget.addEventListener('mouseout', () => this.hideMessage(), false);
    const messageContainer = document.getElementById(this.messageContainerIdValue);
    messageContainer.parentElement.addEventListener('mouseover', () => this.showMessage(), false);
    messageContainer.parentElement.addEventListener('mouseout', () => this.hideMessage(), false);

    const closeElement = document.getElementById(this.closeElementIdValue);
    closeElement.addEventListener('click', () => this.hideMessage(), false);
  }

  showMessage() {
    const messageContainer = document.getElementById(this.messageContainerIdValue);
    messageContainer.style.display = 'block';
  }

  hideMessage() {
    const messageContainer = document.getElementById(this.messageContainerIdValue);
    messageContainer.style.display = 'none';
  }
}
