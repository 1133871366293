import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static debounces = ['submit']

  connect() {
    const input = document.getElementById('search-upload');
    input.focus();
    input.setSelectionRange(-1, -1);
    useDebounce(this, { wait: 650 });
  }

  submit() {
    this.element.requestSubmit();
  }
}
