import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['cardElement', 'cardErrors', 'form']

    connect() {
      // eslint-disable-next-line no-undef
      const stripe = Stripe(this.data.get('key'));
      const elements = stripe.elements();
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      const card = elements.create('card', {
        style,
      });

      card.mount(this.cardElementTarget);

      // Handle real-time validation errors from the card Element.
      const controller = this;
      card.addEventListener('change', (event) => {
        const displayError = controller.cardErrorsTarget;
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });

      // Handle form submission.
      const form = controller.formTarget;
      const setupIntentId = form.dataset.setupIntent;
      const paymentIntentId = form.dataset.paymentIntent;
      const errorElement = this.cardErrorsTarget;
      if (paymentIntentId) {
        if (form.dataset.status === 'requires_action') {
          stripe.confirmCardPayment(paymentIntentId, { setup_future_usage: 'off_session' }).then((result) => {
            if (result.error) {
              errorElement.textContent = result.error.message;
              form.querySelector('#card-details').classList.remove('d-none');
            } else {
              form.submit();
            }
          });
        }
      }

      const data = {
        payment_method_data: {
          card,
        },
      };
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        if (paymentIntentId) {
          stripe.confirmCardPayment(paymentIntentId, {
            payment_method: data.payment_method_data,
            setup_future_usage: 'off_session',
            save_payment_method: false,
          }).then((result) => {
            if (result.error) {
              errorElement.textContent = result.error.message;
              form.querySelector('#card-details').classList.remove('d-none');
            } else {
              form.submit();
            }
          });
        } else if (setupIntentId) {
          // eslint-disable-next-line no-inner-declarations
          function addHiddenField(name, value) {
            const input = document.createElement('input');
            input.setAttribute('type', 'hidden');
            input.setAttribute('name', name);
            input.setAttribute('value', value);
            form.appendChild(input);
          }

          stripe.confirmCardSetup(setupIntentId,
            { payment_method: data.payment_method_data }).then((result) => {
            if (result.error) {
              errorElement.textContent = result.error.message;
            } else {
              addHiddenField('payment_method_token', result.setupIntent.payment_method);
              form.submit();
            }
          });
        }
      });
    }
}
