import NestedForm from 'stimulus-rails-nested-form';

export default class extends NestedForm {
  static values = {
    wrapperSelector: String,
    actionSelector: String,
    targetInsertPosition: String,
  }

  initialize() {
    this.wrapperSelector = this.wrapperSelectorValue || '.nested-form-wrapper';
    this.actionSelector = this.actionSelectorValue || '.nested-form-action';
    this.targetInsertPosition = this.targetInsertPositionValue || 'beforebegin';
  }

  insertHTML(target, content) {
    target.insertAdjacentHTML(this.targetInsertPosition, content);
  }

  add(e) {
    e.preventDefault();

    const actionSelector = e.target.closest(this.actionSelector);

    if (actionSelector) {
      const { model } = actionSelector.dataset;
      const templateTarget = this.templateTargets.find((el) => el.dataset.modelTemplate === model);
      const contentTarget = this.findTarget(actionSelector.dataset.targetId);
      const content = this.generateContent(templateTarget);
      this.insertHTML(contentTarget, content);
    } else {
      const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime());
      this.insertHTML(this.targetTarget, content);
    }
  }

  findTarget(targetId, model = null) {
    if (targetId) {
      return this.targetTargets.find((el) => el.id === targetId);
    }

    return this.targetTargets.find((el) => el.dataset.modelTarget === model);
  }

  /* eslint-disable class-methods-use-this */
  generateContent(template) {
    const indexKey = template.dataset.indexKey || 'NEW_RECORD';
    const content = template.innerHTML.replace(new RegExp(`${indexKey}`, 'g'), new Date().getTime());

    return content;
  }
  /* eslint-enabled class-methods-use-this */
}
