import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import { post } from '@rails/request.js';

window.Turbo = Turbo;

export default class extends Controller {
  static targets = ['button']

  static values = {
    apiKey: String,
    cloudName: String,
    mediaAllowed: String,
    maxSizeInMB: Number,
    maxFilesAllowed: Boolean,
    urlSingUploads: String,
    urlUploads: String,
  }

  connect() {
    this.buttonTarget.addEventListener('click', () => this.showWidget(), false);
  }

  showWidget() {
    const myWidget = window.cloudinary.createUploadWidget({
      sources: ['local'],
      maxFiles: this.maxFilesAllowedValue,
      maxVideoFileSize: this.getMaxSizeAllowedInBytes(this.maxSizeInMBValue),
      clientAllowedFormats: this.getMediaAllowed(this.mediaAllowedValue),
      cloudName: this.cloudNameValue,
      apiKey: this.apiKeyValue,
      source: 'uw',
      timestamp: Math.round((new Date()).getTime() / 1000),
      uploadSignatureTimestamp: Math.round((new Date()).getTime() / 1000),
      uploadSignature: this.generateSignature,
      showPoweredBy: false,
    },
    (error, result) => {
      if (!error && result && result.event === 'success') {
        this.saveVideoAsPublicId(result.info.public_id, result.info.original_filename);
      }
    });

    myWidget.open();
  }

  generateSignature = async (callback, paramsToSign) => {
    // This sign is not saved in the database. Is used by Cloudinary temporally.
    const response = await post(this.urlSingUploadsValue, {
      body: JSON.stringify({ data: paramsToSign }),
      contentType: 'application/json',
      headers: { 'X-CSRF-Token': this.getMetaValue('csrf-token') },
      responseKind: 'json',
    });
    if (response.ok) {
      const json = await response.json;
      callback(json.sign);
    } else {
      console.log(response);
    }
  };

  async saveVideoAsPublicId(publicId, originalFilename) {
    const file = { public_id: publicId, name: originalFilename };
    const response = await post(this.urlUploadsValue, {
      body: JSON.stringify({ upload: file }),
      contentType: 'application/json',
      headers: { 'X-CSRF-Token': this.getMetaValue('csrf-token') },
      responseKind: 'turbo-stream',
    });
    if (!response.ok) {
      const renderTurbo = async () => {
        const html = await response.text;
        Turbo.renderStreamMessage(html);
      };
      renderTurbo();
    }
  }

  getMediaAllowed(railsConfigurationMediaAllowed) {
    // input "video/mp4,video/quicktime,video/webm,video/x-ms-wmv,
    // video/x-msvideo,video/x-matroska,video/x-flv,video/h264"
    const regExp = new RegExp('video/', 'g');

    // We have to return ['mp4','quicktime','webm','x-ms-wmv',
    // 'x-msvideo','x-matroska','x-flv','h264']
    return railsConfigurationMediaAllowed.replace(regExp, '').split(',');
  }

  getMaxSizeAllowedInBytes(sizeInMb) {
    return parseInt(sizeInMb, 10) * 1048576;
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
