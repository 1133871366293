import { Controller } from 'stimulus';

// NOTE: This controller has an extra behavior more than just toggle a related wrapper
// to the radio buttons. We must change this and generalize the controller but
// this is the initial implementation.
export default class extends Controller {
  connect() {
    const radioButtons = this.element.querySelectorAll('input[type=radio]');
    this.currentOptionsId = Array.prototype.filter.call(radioButtons, (b) => b.checked)[0].id;
  }

  toggleContent(e) {
    this.toggleHidden(); // Hide last content
    this.toggleDisabled();
    this.clearInputs();
    this.currentOptionsId = e.target.id;
    this.toggleDisabled();
    this.toggleHidden(); // Display new content
  }

  toggleHidden() {
    const currentContent = this.getCurrentContent();

    if (currentContent) {
      currentContent.toggleAttribute('hidden');
    }
  }

  getCurrentContent() {
    return this.element.querySelector(`#${this.currentOptionsId}_content`);
  }

  toggleDisabled() {
    const currentContent = this.getCurrentContent();

    if (currentContent) {
      currentContent.querySelectorAll('input').forEach((i) => i.toggleAttribute('disabled'));
    }
  }

  clearInputs() {
    const currentContent = this.getCurrentContent();

    if (currentContent) {
      currentContent.querySelectorAll('input').forEach((i) => { i.value = ''; }); // eslint-disable-line no-param-reassign
    }
  }
}
