import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form']

  static values = {
    url: String,
  }

  connect() {
    this.delayedQuote();
  }

  quote() {
    const form = this.formTarget.cloneNode(true);

    form.classList.add('hidden');
    form.action = this.urlValue;
    form.setAttribute('novalidate', 'novalidate');
    document.body.append(form);
    form.requestSubmit();
    form.remove();
  }

  delayedQuote() {
    setTimeout(() => {
      this.quote();
    }, 200);
  }
}
